<template>
  <div>
    <strong class="workplatform-title">套装内产品：</strong>
    <div v-for="(item,index) in typeList" :key="index" @click="handleChangeProduct(item.productId)"
        :class="currentProductId===item.productId?'workplatform-radio-button-active':'workplatform-radio-button'">{{item.productName}}</div>

    <component ref="editComponents" class="p-t-5" v-if="customskuId" :is="editComponent" :params="params" :customskuId='customskuId'
      @on-update-map="updateMapStations" @on-update-order="updateOrder"></component>
  </div>
</template>

<script>
import { getCustomInfo } from '@/api/order/custom'

export default {
  props: {
    params: {
      type: Object
    }
  },
  components: {
    // 编辑组件
    eMetroComponent: (resolve) => {
      require(['./Metro'], resolve)
    },
    eThemeComponent: (resolve) => {
      require(['./Theme'], resolve)
    },
    eSubwayComponent: (resolve) => {
      require(['./Subway'], resolve)
    },
    eStairSignComponent: (resolve) => {
      require(['./StairSign'], resolve)
    },
    eMetroSeriesComponent: (resolve) => {
      require(['./MetroSeries'], resolve)
    },
    eElecChooseComponent: (resolve) => {
      require(['./ElecChoose'], resolve)
    }
  },
  data () {
    return {
      currentProductId: 0,
      typeList: [],
      customskuId: null,
      editComponent: ''
    }
  },
  methods: {
    getPage () {
      this.customskuId = null
      const postData = {
        orderId: this.params.orderId,
        customproductId: this.params.productId
      }

      getCustomInfo(postData).then(res => {
        this.customskuId = res.customskuId
        this.typeList = res.productTypeBeanList

        this.loadEditComponent(this.typeList[0])
      })
    },
    loadEditComponent (product) {
      this.currentProductId = product.productId

      const componentMap = [
        { productType: 1, name: 'eMetro' },
        { productType: 2, name: 'eTheme' },
        { productType: 3, name: 'eSubway' },
        { productType: 4, name: 'eStairSign' },
        { productType: 9, name: 'eMetroVoice' },
        { productType: 14, name: 'eElecChoose' },
        { productType: 16, name: 'eMetroSeries' },
        { productType: 18, name: 'eMetro' }
      ]
      const component = componentMap.find(x => x.productType === product.type) || { name: '' }
      this.editComponent = component.name + 'Component'
      this.params.productId = product.productId
    },
    handleChangeProduct (productId) {
      this.currentProductId = productId
      this.params.productId = productId
      this.$refs.editComponents.initPageData()
    },
    updateMapStations (res) {
      this.$emit('on-update-map', res)
    },
    updateOrder (val) {
      this.$emit('on-update-order', this.params.categoryId)
    }
  },
  mounted () {
    this.getPage()
  },
  watch: {
    params: function () {
      this.getPage()
    }
  }
}
</script>
